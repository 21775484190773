<template>
    <div class="q-pa-md">
        <q-table
        title="Правообладатели"
        :data="data"
        :columns="columns"
        row-key="id"
        no-data-label="Нет данных"
        no-results-label="Нет данных"
        @row-click="onRowClick"
         :rows-per-page-options="[20]"
        table-header-class="bg-grey-2"
        >
             <template v-slot:top-right>
                <q-btn unelevated no-caps :color="showSearch ? 'grey-4' : 'grey-1'" text-color="grey-8" icon="search" label="Поиск" @click="showSearch =! showSearch"/>
                <q-btn unelevated no-caps color="grey-1" text-color="green-9" icon="add" label="Создать" @click="onCreate"/>
            </template>
             <template v-if="showSearch" v-slot:top-row>
                <q-tr>
                <q-td colspan="100%">
                    Тут будет поиск
                </q-td>
                </q-tr>
            </template>
            <template v-slot:header-cell="props">
                <q-th :props="props">
                    <strong>{{ props.col.label }}</strong>
                </q-th>
            </template>
           
        </q-table>
        <modal-form :openDialog="openDialog" @close="openDialog = false"/>
    </div>
</template>

<script>
import ModalForm from './form.vue'

export default {
    components: { ModalForm },
    created(){
        this.$store.dispatch('copyrightHolders/fetchData')
    },
    data () {
        return {
            openDialog: false,
            columns: [
                { name: 'name', required: true, label: 'ФИО', align: 'left', field: 'name', sortable: true },
                { name: 'address', align: 'left', label: 'Адрес', field: 'address', sortable: true },
                { name: 'phone', align: 'left', label: 'Телефон', field: 'phone', sortable: true },
                { name: 'email',  align: 'left', label: 'Email', field: 'email', sortable: true},
            ],
            showSearch:false
        }
    },
    computed:{
        data(){
            return this.$store.state.copyrightHolders.data
        },
        typeColor(){
            return val => val === 1 ? 'primary' : 'info'
        },
        paymentStatusColor(){
            return val => val === 1 ? 'text-red-4': 'secondary'
        },
        cost(){
            return val => val.type.id === 1 ? val.claimCost : val.caseCost
        }
    },
    methods: {
        onRowClick(evt, row){
            this.$store.dispatch('copyrightHolders/fetchItemById', row.id)
            this.openDialog = true
        },
        onCreate(){
            this.$store.commit('copyrightHolders/SET_EMPTY_ITEM')
            this.openDialog = true
        },
    },
    
}
</script>

<style scoped>
    /* td:first-child{
        background-color: #f5f5dc
    } */
</style>