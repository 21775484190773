<template>
    <div>
        <table-ch/>
    </div>
</template>

<script>
import TableCh from '@/components/copyright_holders/table.vue'
export default {
  components: { TableCh },
    data () {
        return {
           
        }
    }
    
}
</script>